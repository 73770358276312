import {
    Button,
    ListItem,
    Menu,
    Modal,
    SelectField,
    Table,
    TextField,
    Typography,
    UseTableCellProps,
    useAlert,
    useTable,
    Checkbox,
} from "matsuri-ui"
import { Link, useNavigate } from "react-router-dom"
import { PageHeader } from "../components/PageHeader/PageHeader"
import { ScopeModal } from "../parts/ScopeModal"
import {
    User,
    UserScope,
    displayAuthority,
    requestCreateUser,
    requestDeactivateUser,
    requestSetScope,
    useUsers,
    ActivationStatus,
    displayActivationStatus,
    requestSendActivationEmail,
} from "../hooks/useUsers"
import { css } from "@emotion/react"
import { useAuth } from "m2m-components/storage/useM2mAuth"
import { useAuthGuardCtx } from "../hooks/useAuthGuard"
import React, { useCallback, useMemo, useState } from "react"
import MoreIcon from "@mui/icons-material/More"

const Authorities = ["GeneralUser", "Manager", "Admin"]

const CreateUser: React.FC<{ refetch: () => void }> = ({ refetch }) => {
    const { token } = useAuth()
    const { addAlert } = useAlert()

    const [open, setOpen] = useState(false)
    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])
    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const navigate = useNavigate()

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const formData = new FormData(event.currentTarget)
            const input = Object.fromEntries(formData)
            const { data, error } = await requestCreateUser(token, {
                name: input["name"] as string,
                email: input["email"] as string,
                password: input["password"] as string,
                authority: input["authority"] as string,
            })

            if (error) {
                addAlert(`ユーザー作成に失敗しました: ${error}`, {
                    severity: "error",
                })
            } else {
                addAlert("ユーザーを作成しました", {
                    severity: "success",
                    duration: 3500,
                })
            }

            handleClose()
            refetch()

            if (data?.id) {
                navigate(`/users/${data.id}`)
            }
        },
        [addAlert, handleClose, navigate, refetch, token]
    )

    return (
        <>
            <Button variant="filled" color="primary" onClick={handleOpen}>
                ユーザーを作成
            </Button>

            <Modal
                backdrop
                width={500}
                maxWidth={500}
                open={open}
                onClose={handleClose}
                onClickOutside={handleClose}
                header={<Typography variant="h3">ユーザーの作成</Typography>}
                body={
                    <form
                        onSubmit={handleSubmit}
                        css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 48px;
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                gap: 24px;
                            `}
                        >
                            <TextField name="name" label="name" />
                            <TextField name="email" label="email" />
                            <TextField
                                name="password"
                                label="password"
                                type="password"
                                pattern="^.{8,30}$"
                                validationMessages={{
                                    patternMismatch:
                                        "8文字以上30文字以下で入力してください",
                                }}
                            />
                            <SelectField
                                name="authority"
                                label="authority"
                                options={Authorities.map((v) => ({
                                    value: v,
                                    label: v,
                                }))}
                                defaultValue="GeneralUser"
                            />
                        </div>
                        <Button
                            variant="filled"
                            color="primary"
                            type="submit"
                            css={css`
                                align-self: flex-end;
                            `}
                        >
                            送信する
                        </Button>
                    </form>
                }
            />
        </>
    )
}

const IdCell: React.FC<UseTableCellProps<User, "id">> = ({ cell }) => {
    return <Link to={`/users/${cell.value}`}>{cell.value}</Link>
}

const AuthorityCell: React.FC<UseTableCellProps<User, "authority">> = ({
    cell,
}) => {
    return <>{displayAuthority(cell.value)}</>
}

const ScopeCell: React.FC<UseTableCellProps<User, "scope">> = ({ cell }) => {
    return (
        <Typography
            css={css`
                word-break: break-all;
            `}
        >
            {JSON.stringify(cell.value)}
        </Typography>
    )
}

const UserTableColumns = {
    id: {
        Header: "Id",
        Cell: IdCell,
    },
    name: {
        Header: "Name",
    },
    email: {
        Header: "Email",
    },
    authority: {
        Header: "Authority",
        Cell: AuthorityCell,
    },
    scope: {
        Header: "Scope",
        Cell: ScopeCell,
    },
    userStatus: {
        Header: "UserStatus",
    },
}

export const ListUsers: React.FC = () => {
    const [activationStatuses, setActivationStatuses] = useState<
        ActivationStatus[]
    >(["Active"])

    const { token } = useAuthGuardCtx()
    const { data: users, refetch } = useUsers(
        token,
        activationStatuses.join(",")
    )
    const usersTableData = useMemo(
        () =>
            users?.map((user) => {
                return user
            }) ?? [],
        [users]
    )

    const { headers, rows } = useTable({
        columns: UserTableColumns,
        data: usersTableData,
    })

    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const open = useMemo(() => Boolean(anchorEl), [anchorEl])
    const [selectedUserIndex, setSelectedUserIndex] = useState<number>()
    const handleClick = useCallback(
        ({ currentTarget }: React.SyntheticEvent) => {
            const attr = currentTarget.getAttribute("data-row-index")
            setSelectedUserIndex(attr ? parseInt(attr, 10) : undefined)
            setAnchorEl((prev) => (prev ? null : currentTarget))
        },
        []
    )
    const { throwAlert } = useAlert()

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const [scopeModalOpen, setScopeModalOpen] = useState(false)
    const handleEditScope = useCallback(() => {
        handleClose()
        setScopeModalOpen(true)
    }, [handleClose])
    const handleCloseEditScope = useCallback(() => setScopeModalOpen(false), [])

    const selectedUserScope = useMemo(
        () =>
            selectedUserIndex !== undefined
                ? usersTableData[selectedUserIndex]?.scope
                : undefined,
        [selectedUserIndex, usersTableData]
    )

    const { addAlert } = useAlert()

    const handleUpdateScope = useCallback(
        async (scope: UserScope) => {
            if (selectedUserIndex !== undefined) {
                const { error } = await requestSetScope(token, {
                    userId: usersTableData[selectedUserIndex].id,
                    scope,
                })

                if (error) {
                    addAlert(`エラーが発生しました: ${JSON.stringify(error)}`, {
                        severity: "error",
                    })
                } else {
                    addAlert(`スコープを更新しました`, {
                        severity: "success",
                        duration: 3500,
                    })
                }
            }

            handleCloseEditScope()
            refetch()
        },
        [
            addAlert,
            handleCloseEditScope,
            refetch,
            selectedUserIndex,
            token,
            usersTableData,
        ]
    )

    const handleDeactivateUser = useCallback(async () => {
        if (selectedUserIndex === undefined || !users) {
            return
        }

        const ok = window.confirm("ユーザーを無効化します。よろしいですか？")
        if (!ok) {
            return
        }

        const { error } = await requestDeactivateUser(
            token,
            users[selectedUserIndex].id
        )
        if (error) {
            addAlert(`ユーザーの無効化に失敗しました: ${error}`, {
                severity: "error",
            })
        } else {
            addAlert(`ユーザーの無効化に成功しました`, {
                severity: "success",
                duration: 3500,
            })
        }

        handleClose()
        refetch()
    }, [addAlert, handleClose, refetch, selectedUserIndex, token, users])

    return (
        <>
            <PageHeader>
                <Typography variant="h2">ユーザー一覧</Typography>
            </PageHeader>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                `}
            >
                <div
                    css={css`
                        align-self: flex-end;
                    `}
                >
                    <CreateUser refetch={refetch} />
                </div>
                <div
                    css={css`
                        display: flex;
                        gap: 24px;
                    `}
                >
                    <Checkbox
                        onChange={(e) => {
                            setActivationStatuses((prev) => {
                                if (e.target.checked) {
                                    return [...prev, "Deactivated"]
                                } else {
                                    return prev.filter(
                                        (status) => status !== "Deactivated"
                                    )
                                }
                            })
                        }}
                    >
                        無効化されたユーザーを含める
                    </Checkbox>
                    <Checkbox
                        onChange={(e) => {
                            setActivationStatuses((prev) => {
                                if (e.target.checked) {
                                    return [...prev, "BeforeVerification"]
                                } else {
                                    return prev.filter(
                                        (status) =>
                                            status !== "BeforeVerification"
                                    )
                                }
                            })
                        }}
                    >
                        認証前のユーザーを含める
                    </Checkbox>
                </div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            {headers.map((column) => (
                                <Table.HeaderCell key={column.key}>
                                    {column.render()}
                                </Table.HeaderCell>
                            ))}
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body striped>
                        {rows.map((row, index) => (
                            <Table.Row key={row.key}>
                                {row.cells.map((cell) => {
                                    if (cell.key === "userStatus") {
                                        return (
                                            <Table.Cell key={cell.key}>
                                                <div>
                                                    {displayActivationStatus(
                                                        cell.props.row.values
                                                            .activationStatus
                                                    )}
                                                    {cell.props.row.values
                                                        .activationStatus ===
                                                        "BeforeVerification" && (
                                                        <Button
                                                            color="primary"
                                                            variant="outlined"
                                                            onClick={async () => {
                                                                const {
                                                                    error,
                                                                } = await requestSendActivationEmail(
                                                                    cell.props
                                                                        .row
                                                                        .values
                                                                        .email
                                                                )
                                                                throwAlert(
                                                                    error,
                                                                    {
                                                                        errorMessage:
                                                                            "メール再送信に失敗しました",
                                                                        successMessage:
                                                                            "認証メールを再送信しました",
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            再送信
                                                        </Button>
                                                    )}
                                                </div>
                                            </Table.Cell>
                                        )
                                    }

                                    return (
                                        <Table.Cell key={cell.key}>
                                            {cell.render()}
                                        </Table.Cell>
                                    )
                                })}
                                <Table.Cell>
                                    <Button
                                        icon={<MoreIcon />}
                                        onClick={handleClick}
                                        data-row-index={index}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClickOutside={handleClose}
                        >
                            <ListItem onClick={handleEditScope}>
                                Scopeを編集
                            </ListItem>
                            <ListItem onClick={handleDeactivateUser}>
                                ユーザーを無効化
                            </ListItem>
                        </Menu>
                    </Table.Body>
                </Table>
            </div>

            <ScopeModal
                defaultScope={selectedUserScope}
                open={scopeModalOpen}
                onClose={handleCloseEditScope}
                onClickOutside={handleCloseEditScope}
                onSubmit={handleUpdateScope}
            />
        </>
    )
}
